/* login css file  */

.Login_container{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1000px;
    max-width: 100%;
    padding: 15px;
}
.forgot-password {
    text-align: right;
    font-size: 13px;
    margin: 0 0 15px;
 }

.label {
    font-size: 15px;
    color: #403f49;
}

.loginBackground {
    background-color: #dd761c !important;
    min-height: 400px;

    /* background: url('../images/loginbg.png');
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center  !important; 
    height: 100%;
    width: 100%; */
    position: relative;
    
}
.logoBg {
position:absolute;
bottom: 85px;
right: 5px
}
.loginBackground .logoBg {
    transform: translate(0%, 40%);
    width: 100%;
    height: 100%;
    text-align: center;
}
.logoBg .logoIc{
    max-width: 200px;
}
.logoBg .title {
    color: #F9FAFD;
}
.Login_container > .card{
    border: 0;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 13px #0000001f;
}

.Login_heading{
    font-size: 30px;
    margin: 0 0 30px;
}
.frgt .Login_heading{ margin: 0 0 15px; }

.loginWraper .frgt .descp { margin-bottom: 35px; }


.loginWraper .formBg {  padding: 4rem 3rem 2rem; }

.loginWraper .form-control {
    background-color: #F9FAFD;
    border: 1px solid #0003;
    outline: 0;
    border-radius: 5px;
    font-weight: 400;
}
.loginWraper .frgt{ color: #666; text-decoration: none;  }

.loginWraper .frgt:hover{ color: #9a5e2a;  }

.loginWraper .form-group {
    margin: 1rem 0;
}

.loginWraper .buttonSub{
    background-color: #dd761c;
    color: white;
    border-radius: 5px;
    width: 100%;
    min-height: 45px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0.4px;
    font-size: 16px;
    transition: all ease 0.5s;
    margin: 15px 0;
    outline: 0;
    box-shadow: inherit;
}
.loginWraper .buttonSub:hover{ background-color: #9f500c; color: white; }

.loginWraper .LinkSty{ 
    text-align: center;
    color: #666;
    font-size: 14px;
    padding: 20px 0 0;
    display: block;
    margin: 0;
    text-decoration: none;
    font-weight: 400;
}
.loginWraper .LinkSty:hover span{ color:#403f49 }

.loginWraper .descp {
    font-size: 13px;
    color: #666;
    font-weight: 300;
    line-height: 16px;
}

.mailWrap .mailIc{ max-width: 17px;  transition: all ease 0.3s; }

.loginWraper .mailWrap{ padding: 4rem 2rem 2rem; }

.mailWrap .btns{
    background-color: #FFF;
    color: #0a0071;
    border-radius: 60px !important;
    margin: 5px !important;
    border: 2px solid #0a0071;
    font-size: 14px;
    padding: 5px 5px;
    transition: all ease 0.5s;
}
.mailWrap .btns:hover, .mailWrap .btns:focus {  background-color: #0a0071;  color: #FFF;}

.mailWrap .btns:hover img.mailIc, .mailWrap .btns:focus img.mailIc {  filter:  brightness(0) invert(1); }

.centered.loginWraper{ min-height: 90vh; }







@media only screen and (max-width: 767px) {

    .Login_container {
        position: absolute;
        top: 10%;
        left: 0;
        transform: inherit;
        width: 1000px;
        max-width: 100%;
        padding: 15px;
    }
    .centered.loginWraper{ height: 100%; padding: 20px 0; }

    .loginWraper .mailWrap { padding: 3rem 1rem 2rem; }

    .d-flex.loginBoxs {display: flex !important; flex-direction: column; }

    .loginBackground {  min-height: 110px; }
    
    .loginWraper .formBg {  padding: 2rem 2rem 1rem;  }

}

@media only screen and (max-width: 480px) {

    .formBg.mailWrap .btn-group {display: flex; flex-direction: column; }

    .mailWrap .btns {  font-size: 15px;  padding: 8px 5px; }

    .Login_heading { font-size: 26px;margin: 0 0 25px }

    .loginWraper .formBg {  padding: 2rem 1.5rem 2rem; }
    
    .Login_container {  top: 10%; position: relative; }
}