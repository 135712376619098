/* Sidebar */

.sidebar {
    background: #dd761c;
    padding: 0 5px;
    width: 245px;
    z-index: 11;
    transition: width 0.25s ease, background 0.25s ease;
    -webkit-transition: width 0.25s ease, background 0.25s ease;
    -moz-transition: width 0.25s ease, background 0.25s ease;
    -ms-transition: width 0.25s ease, background 0.25s ease;
    height: 100vh;
}
.sidebarWraper{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 181;
}
.sidebar.customClassA{
    width:70px;
}
.sidebar > .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
}
.sidebar > .nav:not(.sub-menu) > .nav-item > .nav-link {
    background: transparent;
    transition-duration: 0.3s;
    transition-timing-function: "ease-in-out";
    transition-property: "background", "color", "box-shadow", "padding";
}
.sidebar > .nav:not(.sub-menu) > .nav-item {
    padding: 6px 0;
}
.sidebar > .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 40px 0px;
}
.sidebar  .nav > .nav-item:not(.nav-profile) > .nav-link {
    position: relative;
}

.sidebar  .nav .nav-item.active > .nav-link {
    color: #253858;
}

.sidebar .nav .nav-item .nav-link {
    align-items: left;
    display: flex;
    padding: 8px 8px;
    white-space: nowrap;
    color: #253858;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
    opacity: 0.7;
    min-height: 36px;
}
.sidebar .nav .nav-item .nav-link img.menu_icon, .sidebar .accordion-button img.menu_icon { margin-right: 10px; max-height: 35px; transition-duration: 0.3s; margin-left: 10px;width: 25px;}

.sidebar  .nav:not(.sub-menu) > .nav-item:hover:not(.nav-profile):not(.hover-open) > .nav-link:not([aria-expanded="true"]), .sidebarAccord .accordion-button:hover  {
    background: #091E4214;
    border-radius: 8px;
    color: #253858;
    text-decoration: none;
    opacity: 1;
}
/* .sidebar > .nav:not(.sub-menu) > .nav-item:hover:not(.nav-profile):not(.hover-open) > .nav-link:not([aria-expanded="true"]) img {
    filter: invert(46%) sepia(69%) saturate(613%) hue-rotate(58deg) brightness(96%) contrast(85%);
} */
.main-container{
    display: flex;
    justify-content: space-between;
    min-height: calc(100% - 81px);
}
.dashboard_cover{ flex: 1; }

.sidebar-brand.brand-logo{
    max-width: 100%;
    max-height: 90px;
}

.sidebar .cars-logo {
    position: absolute;
    width: 240px;
    bottom: 50px;
    left: 0;
    z-index: -1;
    max-width: 100%;
}

.sidebar-brand-wrapper {
    padding: 10px 10px 0;
    min-height: 80px;
}
.sidebar.customClassA > .nav { padding-right: 10px; }

.dashHeader.bg-white{
    min-height: 70px;
    padding: 10px 15px;
}
.dashHeader .top_title {
    font-size: 20px;
    color: #406bd6;
    letter-spacing: 0.1px;
}
.dashHeader .dropsToggle{
    float: left;
}
.dashHeader .dropsToggle .toggle-button__line{ background: #2067d1; border-radius: 3px; height: 3px; }

.sidebar .nav .nav-item{  width: 100%; }

.sidebarAccord .accordion-body {  padding:0 5px; }

.sidebarAccord .accordion-header{ border: 0; }

.sidebarAccord .accordion-button {
    color: #253858;
    background-color: transparent;
    box-shadow: inherit;
    border: 0;
    padding: 8px 8px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.3px;
    opacity: 0.7;
}
.sidebarAccord  .accordion-item { background-color: transparent; border: 0; }

.sidebarAccord .accordion-body .nav-link{ font-weight: 400 !important; }

/*.sidebarAccord  .accordion-button::after{   
    background-image: url(../icons/minuse.png);
    width: 13px;
    height: 13px;
    background-size: cover;
}

.sidebarAccord  .accordion-button.collapsed::after{ 
    background-image: url(../icons/plus.png);
    width: 13px;
    height: 13px;
    background-size: cover;
 }*/
 .sidebarAccord  .accordion-header { margin: 0; }
 #cspace:hover{
    color: rgb(13, 44, 92);
    cursor:pointer;
    font-weight: 500;
    font-size: 16px;
  }

  .changeColor {
    color: #6B778C;
  }
 





/* MEDIA_QUERY START*/

@media only screen and (max-width:767px) {

    .dashboard_cover { flex: 1; width: 100%; }
    .sidebar.customClassA {   width: 0; }
    .sidebar-brand-wrapper {  padding: 10px 15px; min-height: auto;  }
    .sidebar > .nav { margin: 0px;}
    .sidebarWraper { height: 100%; overflow-y: scroll; }
    
    
}
@media only screen and (max-width:580px) {

    .sidebar-brand.brand-logo { max-height: 60px; }
    .sidebar .nav .nav-item .nav-link { font-size: 15px; }
    .sidebar .nav .nav-item .nav-link img {  margin-right: 10px;  }

    
}
.sidebar .nav .nav-item .nav-link span.menu-title, .sidebar .accordion-button span.menu-title { margin-right: 10px; transition-duration: 0.3s;  color: #FFF; padding: 3px;}