.spinnerbackground {
    width: 100%;
    height: 100%;
    /* background-color: #f6f3f3;
    opacity: .3; */
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.834);
    z-index: 1;
    

  }