
.pointer { cursor: pointer; }

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

header {
  background-color: #FFF;
  box-shadow: 0 0 5px #0000000f;
  float: left;
  width: 100%;
  top: 0;
  z-index: 180;
  left: 0;
}

header.headWid{
  position: fixed;
  top: 0;
  z-index: 180;
  left: 245px;
  width: calc(100% - 245px);
}
header.header_collapse{
  position: fixed;
  top: 0;
  z-index: 180;
  left: 0;
  width: 100%;
}
.headTitle{ color: #505F79; font-size: 18px; }

header .navbar-light { box-shadow: inherit !important; }

/* Style the navigation bar links */
header .navbar .navbar-nav .nav-link {
  color: #6B778C;
  text-align: center;
  padding: 8px 12px 8px 12px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  letter-spacing: 0.2px;
  font-size: 15px;
  border-radius: 7px;
}

.headerLogo{
  display: flex;
  justify-content:center;
}
header .navbar .nav-item { padding: 0 5px; }

header .navbar .nav-link:hover{ color:#6554C0 !important; background-color: transparent; }

/* Active/current link */
header .navbar .nav-link.active{ color:#6554C0 !important; background-color:#EAE6FF; }

/* header .navbar .active.nav-link:after{ 
  content: ' ';
  background: #0A0072;
  height: 4px;
  width: 35px;
  display: inline-block;
  position: absolute;
  bottom: 3px;
  left: 5px;
  border-radius: 35px;
} */

/* Right-aligned link */
.navbar a.right {
  float: right;
}

/* Change color on hover */
/* .navbar a:hover {
  background-color: #ddd;
  color: black;
} */




header .userDrops > button{
  padding: 0;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 100%;

  background-color: #f6f7f9;
  border-color: #0A0072;
}
header .userDrops > button:hover, header .userDrops > button:focus{ border-color: #0A0072; }

header .userDrops > button .profileImg {
  width: 35px;
  height: 35px;
  object-fit: cover;
   background-color: #f6f7f9;
  border-color: #0A0072;
}

.notifDrops{
  padding: 0;
  object-fit: cover;
  background-color: #f6f7f9;
  border-color: #0A0072;
}

.notifDrops.dropdown .dropdown-menu{
  left: inherit !important;
  right: 0px !important;
  margin-top: 9px !important;
  padding: 0;
  width: 320px;
  min-width: auto;
}
header .logoWraper {
  padding: 0;
  margin: 0;
  text-align: center; 
}


header .topRights { display: flex; }

header .topRights .form-search {
  margin-right: 20px;
}
header .mainLogo{
  max-height:35px;
}

header .navbar-light {
  background-color: #FFF !important;
  box-shadow: inherit !important;
  opacity: 1;
}
.form-search .form-control{
  border: 1px solid #C4C4C4;
  border-radius: 35px;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.3px;
}
header .dropdown-menu > a {
  color: #403F49;
  text-align: left;
  padding: 5px 15px;
  font-size: 14px;
}

header .topRights .userDrops.dropdown .dropdown-menu{
  left: inherit !important;
  right: 0px !important;
  margin-top: 10px !important;
  padding: 0;
  width: 120px;
  min-width: auto;
}
header .topRights .dropdown-menu .dropdown-item { white-space: inherit; }


.notifyBtn{
  margin-right: 10px;
  position: relative;
  background: #dd761c !important;
  height: 32px;
  width: 50px;
  border-radius: 10px;
  padding: 3px 5px 3px 5px;
  display: flex;
  flex-direction: row ;
  align-items: center;
  justify-content: space-around;
  border-color: #dd761c !important;
  box-shadow: inherit;
}
.notifyBtn .countsNot {
  display: inline-block;
  color: #FFF;
  font-size: 13px;
  font-weight: 400; 
  margin-left: 8px;
}
.notifyBtn.dropdown-toggle::after{ display: none; }

.notifyBtn .notifiImg {
  max-width: 20px;
}
.toggleButton{
  width: 40px;
  cursor: pointer;
  margin: 0px;
  position: relative;
}

header .contain { 
  padding: 0 15px; 
  display: flex; 
  justify-content: space-between; 
  width: 100%; 
}

.dropdown-menu[data-bs-popper] {
  right:0px;
  min-width: 250px;
  left: auto;
}




@media screen and (max-width: 767px) {

   header .logoWraper{ width: 100%; margin: 0 0 10px; }

   header .topRights { margin: 6px 0 0 0; justify-content: end; width: auto; float: right; }
   
   header .mainLogo {  max-height: 45px; }
   
   /* header.headWid {  min-height: 110px; } */
   
   header.headWid .toggleButton {  margin: 10px 0 0; }


   header .contain { display: block !important; }
}

@media screen and (max-width: 380px) {

  header .logoWraper{ width: 100%; margin: 20px 0px 5px;  }


}