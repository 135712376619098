.popupbackground {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: fixed;
    display: flex;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
  }
  
  .popupcontainer {
    width: 20rem;
    max-width: 100%;
    min-height: 10rem;
    border-radius: 12px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(13, 36, 3, 0.52) 0px 0px 13px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    justify-self: center;
    align-self: center;
  }
 
  .message{
      flex:50%;
      display: flex;
      justify-content: center;
      align-items: center;
      /* font-size: 1.7rem; */
      font-size: 20px !important;
      text-align: center;
      text-align: center;
      font-size: medium;   
      font-size: 35px; 
     
  }
  
  
 
  
  