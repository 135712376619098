.bor15 {
    border-radius: 15px;
}

.mb-35 {
    margin-bottom: 35px;
}

.whiteBoxs {
    background: #FFF;
    box-shadow: 0px 0px 8px 6px #00000024;
}

.textGreen {
    color: #580485 !important;
}

.countBox .counts {
    font-weight: 600;
    font-size: 35px;
    margin: 0 0 15px;
    line-height: 36px;
}

.countBox h6 {
    font-size: 18px;
    color: #8c8c8c;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0 0;
    min-height: 43px;
}

.countBox {
    padding: 5px 15px;
}

.spaceBx {
    padding: 10px 4%;
}

.fillForms .whiteBoxs p {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 14px;
    color: #8c8c8c;
}

@media only screen and (max-width: 580px) {

    .countBox {
        text-align: center;
    }

    .countBox .counts {
        font-size: 30px;
        margin: 0;
    }

    .countBox h6 {
        font-size: 16px;
        margin: 10px 0 5px;
        min-height: auto;
    }
}

.dropDow {
    text-align: right;
}

.reportDetail {
    margin-top: 20px !important;
}

.reportDrop {
    margin-top: 30px !important;
}

.reportDrop.dropdown .dropdown-menu {
    left: inherit !important;
    right: 0px !important;
    margin-top: 9px !important;
    padding: 0;
    width: 150px;
    min-width: auto;
}

.box {
    background: #FFF;
    border-color: rgb(206, 203, 203);
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    box-shadow: #580485;
}

.orderCounts {
    font-size: 25px;
    color: #580485;
    font-weight: 400;
    margin-top: 8px;
}

.orderStatus {
    color: #8c8c8c;
    font-weight: 500;
}

.counterCard {
    background: #FFF;
    border-radius: 15px !important;
    box-shadow: 0px 0px 8px 6px #00000024;
    justify-content: center !important;
    width: 255px;
    padding: 10px;
    height: 100px;
}

.counterCardBottom {
    background: #FFF;
    border-radius: 15px !important;
    box-shadow: 0px 0px 8px 6px #00000024;
    justify-content: center !important;
    width: 255px;
    /* height: 70px; */
    padding: 6px;
    border-bottom: 5px solid #580485;
}

.orderCountsBottom {
    font-size: 27px;
    color: #580485;
    font-weight: 600;
}

.countStatusBottom {
    color: #8c8c8c;
    font-weight: 600;
    font-size: 18px
}

.cardRow {
    display: flex;
    justify-content: flex-start;
}

.menuIcon {
    max-width: 70px !important;
}

.countStatus {
    color: #8c8c8c;
    font-weight: 400;
    font-size: 18px;
}

.betwCard {
    display: flex;
    justify-content: space-around;
}

.table-img {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    object-fit: cover;
}

.negTable .table_design .btn.addWish {
    line-height: 17px;
    padding: 5px 7px;
    min-width: 120px;
    font-size: 13px;
}

.viewBtn {
    padding: 7px 7px;
    font-size: 14px;
    border-radius: 60px;
    border-radius: 5px;
    border-style: none;
    color: white;
    background-color: #580485;
    width: 100px;
    height: 30px;
}

.netprofit {
    height: 30px;
    width: 30px;
    background-color: #82ca9d;
    margin-right: 10px;
    border-radius: 20%;
}

.areagraphcolorindicator {
    font-size: 14px;
}

.revenue {
    height: 30px;
    width: 30px;
    background-color: #8884d8;
    margin-right: 10px;
    border-radius: 20%;
}

.revCard {
    height: 450px;
}

/* ORDER MANAGEMENT */

.container {
    display: flex;
    flex-wrap: wrap;
}

.customCard {
    width: 340px !important;
    background: #FFF;
    border-radius: 15px !important;
    box-shadow: 0px 0px 8px 6px #00000024;
    justify-content: center !important;
    padding: 6px;
}

.title {
    font-size: 16px;
    font-weight: 600;
}

.date {
    font-size: 12px;
    color: #8c8c8c;
}

.section-divider {
    height: 0;
    border-top: 2px solid #8c8c8c;
    opacity: .4;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.section-subDivider {
    height: 0;
    border-top: 1px solid #8c8c8c;
    opacity: .4;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.time {
    font-size: 12px;
    color: black;
    font-weight: 500;
}

.dst {
    font-size: 12px;
    font-weight: 500;
}

.ordrCnt {
    color: #8c8c8c;
}

.amt {
    font-size: 14px;
    font-weight: 500;
    color: orangered;
}

.menuItem {
    font-weight: 500;
    font-size: 13px !important;
    display: flex;
    justify-content: right;
}

.deliv {
    display: flex;
    justify-content: space-between;
}

.review {
    display: flex;
    justify-content: flex-start;
    margin-top: -10px;
}

.restName {
    font-weight: 600;
}

.restMenu {
    font-weight: 600;
    font-size: 14px !important;
}

.starImg {
    max-width: 20px;
    /* margin-top: -2em; */
}

.order_icon {
    height: 40px;
    width: 40px;
    border-radius: 10px;
}

.dot {
    height: 4px;
    width: 4px;
    margin-top: 7px;
    border-radius: 100px;
    background-color: #8c8c8c;
}

.sum {
    font-weight: 600;
    font-size: 14px !important;
}

.orderBtn {
    padding: 7px 7px;
    font-size: 14px;
    border-radius: 5px;
    border-style: none;
    border: 1px solid #46ab46;
    color: #237923;
    background-color: #dffced;
    width: 300px;
    height: 40px;
}

.orderBtnDeliv {
    padding: 7px 7px;
    font-size: 14px;
    border-radius: 5px;
    border-style: none;
    border: 1px solid orangered;
    color: orangered;
    background-color: rgb(240, 226, 221);
    width: 300px;
    height: 40px;
}

.orderBtnPrep {
    padding: 7px 7px;
    font-size: 14px;
    border-radius: 5px;
    border-style: none;
    border: 1px solid rgb(49, 97, 220);
    color: rgb(49, 97, 220);
    background-color: rgb(216, 224, 238);
    width: 300px;
    height: 40px;
}

button.btn-actn {
    background-color: #dd761c !important;   
    height: 35px;
    padding: 0px 20px;
    background: none;
    border: 1px solid #dd761c;
    border-radius: 90px;
    margin-left: 8px;
    font-size: 14px;
    color: white; 
}
.update-section{
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-sctn {
    margin-top: 80px;
}
table, th,td,tr {
    border: 1px solid black;
}
