
/* font-roboto */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  font-size: 14px;
  padding: 0;
  margin: 0;
  background-color: #F4F5F7;
  color: #6B778C;
}

body,
html,
.App,
#root,
.outer {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
.container, .container-lg, .container-md, .container-sm, .container-xl {
  max-width: 1320px;
}
.form-check-input:checked {
  background-color: #344563;
  border-color: #344563;
  box-shadow: inherit;
  outline: 0;
}
.form-check-input:focus{ box-shadow: inherit; }

.wraper{
  float: left;
  width: 100%;
 
  
}
.container{
  max-width: 100%;
  width: 100%;
  padding: 0;
}



.outer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.outer h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}


.textSize {
  font-size: 17px;
}
a {
  transition: all ease 0.5s;
  text-decoration: none;
}
input {
  outline: none;
}
.heading-primary {
  color: #403f49;
}


.contentWraper{
  width: 100%;
  padding: 0;
  
  float: right;
}
.container_main { 
  width:100%;
  margin-left: 195px; 
  margin-top: 50px;
  position: relative;
  background-color: white;
 
}
.container_content_open {
  width: 100%;
  margin-left: 80px;
  margin-top: 30px;
}
.container_content_close {
  width: 100%;
  margin-left: 45px;
  margin-top: 30px;
}
/*.sidebar_open{
  width: 100%;
  margin-left: 80px;
  margin-top: 30px;
}
.sidebar_close{
  width: 100%;
  margin-left: 80px;
  margin-top: 30px;
}*/
.sidebar_close{
   
  margin-left: 80px;
  margin-right: 80px;
  min-height: calc(100vh - 50px);
}
.sidebar_open{
    
  margin-left: 80px;
  margin-right: 30px;
  min-height: calc(100vh - 50px);
}
@media screen and (min-width : 1360px) {
  .sidebar_close{
   
    margin-left: 80px;
    margin-right: 80px;
    
  }
}
@media screen and (min-width : 960px) {
  
}
.container_main.containerSpace{
  width: 100%;
  margin-left: 0;
}

.mainBox{
  border: 0;
  border-radius: 10px;
  min-height: 650px;
  box-shadow: 0 2px 5px #0000001c;
  padding: 2%;
  background-color: #FFF;
}

.btn-primary {
  /* color: #fff;
  border-color: rgb(94,174,255);
  background: rgb(94,174,255);
  background: linear-gradient(0deg, rgba(94,174,255,1) 0%, rgba(45,149,255,1) 100%); */
  transition: all ease 0.5s;
}

/* code for hide scroll bar */
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.appendSidebar{
  width: calc(100% - 275px) !important;
}

.create_btn_empty {
  background: #6554C0;
  color: #FFF;
  border: 1px solid #6554C0;
  padding: 5px 20px;
  border-radius: 5px;
  letter-spacing: 0.3px;
  font-weight: 400;
  min-height: 35px;
  margin: 10px 0;
}
.emptyPage h3.titles {
  font-size: 34px;
  font-weight: 400;
  margin: 15px 0; 
}
.emptyPage p{
  font-size: 15px;
  letter-spacing: 0.1px;
  margin: 15px 0;
}
.emptyPage { padding: 20px 0; }

.emptyPage .emptyImg{ width: 450px; }





@media screen and (max-width: 767px) {
  .container_main { margin-top: 60px; }

}
@media screen and (max-width: 380px) {


}
.dropColor{
  background-color: #580485 !important;
  border-color: #580485 !important;
}