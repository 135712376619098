.footer {
    text-align: center;
    color: #8c8c8c;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    padding: 5px;
    background-color: #fff;
    left: 0px;
    right: 0px;
    height: 40px;
}
.footer_collapse{
    text-align: center;
    color: #8c8c8c;
    justify-content: center;
    width: 100%;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 0px;
    right: 0px;
    padding: 5px;
    background-color: #fff;
    height: 40px;
}